.c-banner{
  border-radius: $value;
  overflow: hidden;
  position: relative;
  background-color: $bg__light;
  @include breakpoint(mobileonly) {
    flex-direction: column;
    display: flex;
  }
  &__info{
    width: 70%;
    @include breakpoint(mobileonly){
      width: 100%;
    }
    & .title{
      color: $color-primary;
      font-size: $value * 4;
      font-weight: 600;
      @include breakpoint(mobileonly){
        font-size: $value * 2;
        
      }
    }
  }
  &__background{
    position: absolute;
    width: 40%;
    height: 100%;
    right: 0;
    top: 0;
    bottom: 0;
    @include breakpoint(mobileonly){
      position: relative;
      width: 100%;
      order: 2;
      // display: none;
    }
    & .image{
      width: 100%;
      height: 100%;
      right: 0;
      top: 0;
      bottom: 0;
      & img{
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
  &--dark{
    background-color: $bg__dark;
    color: white;
    & p{
      color: white;
    }
    & .title{
      color: $color-secondary;
    }
  }
}