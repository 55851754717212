.button {
  border: none;
  outline: none!important;
  color: #fff;
  border-radius: 8px;
  height: 48px;
  min-width: 182px;
  white-space: nowrap;
  line-height: 48px;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  &.button-sm {
    font-size: 12px;
    height: 35px;
    border-radius: 67px;
    line-height: 35px;
    min-width: 110px;
  }
  &--primary {
    background-color: $color-primary;
    &:hover {
      background-color:darken($color-primary,5);
    }
    &:disabled {
      opacity: .5;
    }
  }

  &--secondary {
    background-color: $color-secondary;
    color: white;
    &:hover {
      background-color:darken($color-secondary,5);
      color: white;
    }
    &:disabled {
      opacity: .5;
    }
  }

  &--alternate {
    background-color: $bg__white ;
    border-width: 1px;
    border-style: solid;
    border-color: $bg__dark;
    color: $tx__active;
    &:focus {
      border-width: 2px;
      border-color: darken($bg__dark,5);
    }
    &:disabled {
      border-width: 2px;
      opacity: .3;
    }
  }
  &--default {
    background-color: $bg__light;
    color: $bg__dark;
    &.button--default--qe {
      &.active {
        background-color: $qe__blue;
        color: $bg__white;
      }
    }
    &:focus, &:hover {
      background-color: darken($bg__light,5);;
    }
    &.active {
      background-color: $bg__dark;
      color: $bg__white;
    }
  }

}

.btn{
  &--primary {
    background-color: $color-primary;
    color: white;
    &:hover {
      background-color:darken($color-primary,5);
      color: white;
    }
    &:disabled {
      opacity: .5;
    }
  }
  &--secondary {
    background-color: $color-secondary;
    color: white;
    &:hover {
      background-color:darken($color-secondary,5);
      color: white;
    }
    &:disabled {
      opacity: .5;
    }
  }
  &-ede{
    &--secondary {
      background-color: $ede__primary;
      color: white;
      &:hover {
        background-color:darken($ede__primary,5);
        color: white;
      }
      &:disabled {
        opacity: .5;
      }
    }
  }
}

.btn-clave-unica {
  font-family: Roboto, sans-serif;
  text-decoration: underline;
  padding: 0px auto;
  text-align: center;
  width: 100%;
  height: 64px;
  background-color: #0F69C4;
  color: white;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  line-height: 45px;
  border: 1px solid #0F69C4;
  border-radius: 0px;
  vertical-align: middle;
  cursor: pointer;
  &:hover {
    background-color: #0C549C;
    transition: all 0.3s ease;
  }
  &:disabled {
    opacity: .5;
  }
}

.icon-clave-unica {
  vertical-align: middle;
  padding-left: 10px;
  padding-right: 10px;
}


.btn-crear-clave-unica {
  font-family: Roboto, sans-serif;
  text-decoration: underline;
  padding: 0px auto;
  text-align: center;
  width: 100%;
  height: 100px;
  background-color: rgba(43, 68, 255, 1);
  color: white;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  line-height: 45px;
  border: 1px solid #2B44FF;
  border-radius: 8px;
  vertical-align: middle;
  cursor: pointer;
  &:hover {
    background-color: rgba(43, 68, 255, 0.8);
    transition: all 0.3s ease;
  }
  &:disabled {
    opacity: .5;
  }
}

.btn-crear-registro {
  font-family: 'Poppins', sans-serif;
  padding: 0px auto;
  text-align: center;
  width: 100%;
  height: 64px;
  background-color: white;
  color: black;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  line-height: 45px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  vertical-align: middle;
  cursor: pointer;
  &:hover {
    background-color: rgba(172, 172, 230, 0.1);
    transition: all 0.3s ease;
  }
  &:disabled {
    opacity: .5;
  }
}

.btn-alt {
  background-color: #191847;
}

.btn-registro {
  font-family: 'Poppins', sans-serif;
  padding: 0px auto;
  text-align: center;
  width: 100%;
  height: 48px;
  background-color: rgba(43, 68, 255, 1);
  color: white;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  line-height: 45px;
  border: 1px solid #2B44FF;
  border-radius: 8px;
  vertical-align: middle;
  cursor: pointer;
  &:hover {
    background-color: rgba(43, 68, 255, 0.8);
    transition: all 0.3s ease;
  }
  &:disabled {
    opacity: .5;
  }
}
