.components {
  padding: 80px 0;
  & h4 {
    font-weight: 600;
    color: black;
  }

  & .circle {
    width: 64px;
    height: 64px;
    border-radius: 50%;
  }

  & .button-wraper {
    border: 1px dashed #7B61FF;
    padding: 20px;
  }

  .bg-blue {
    background-color: $color-primary;
  }
  .bg-dark {
    background-color: $bg__dark;
  }
  .bg-text-color {
    background-color: $tx__active;
  }
}
