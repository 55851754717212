.lbl-reg {
    font-size: 12px;
    font-weight: 600;
}

.inp-log {
    width: 100%;
    height: 46px;
    padding: 15px;
    vertical-align: middle;
    background: #FFFFFF;
    border: 1px solid #DDE2E5;
    box-sizing: border-box;
    border-radius: 8px;

    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;

    margin-bottom: 5px;
}

.inp-reg {
    width: 100%;
    height: 46px;
    padding: 15px;
    vertical-align: middle;
    background: #FFFFFF;
    border: 1px solid #DDE2E5;
    box-sizing: border-box;
    border-radius: 8px;

    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;

    margin-bottom: 20px;
}

.inp-reg:focus {
    border: 1px solid #2B44FF;
    outline: none;
}

.inp-sel {
    width: 100%;
    height: 46px;
    padding: 10px;
    vertical-align: middle;
    background: #FFFFFF;
    border: 1px solid #DDE2E5;
    box-sizing: border-box;
    border-radius: 8px;

    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;

    margin-bottom: 20px;
}

.inp-sel:focus {
    border: 1px solid #2B44FF;
    outline: none;
}