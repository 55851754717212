.c-avatar{
  border-radius: 100%;
  width: $value * 8;
  height: $value * 8;
  overflow: hidden;
  position: relative;
  &__image{
   position: absolute;
   width: 100%;
   height: 100%;
   & > img{
    object-fit: cover;
    width: 100%;
    height: auto;
   }
  }
  &--sm{
    width: $value * 4;
    height: $value * 4;
  }
  &--xs{
    width: $value * 2;
  height: $value * 2;
  }
}

.av-name {
  color: white;
  background-color: blue;
}