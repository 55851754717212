.c-card{

  & a {
    text-decoration: none;
  }
  &__image{
    position: relative;
    margin-bottom: $value * 2;
    &::after{
      padding-bottom: 100%;
      content: '';
      display: block;
    }
    & .image{
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      border-radius: $value;
      overflow: hidden;
      & img{
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
  &__info{

  }
  &__data{
    & .date{
      color: $color-secondary;
    }
    margin-bottom: $value;
  }
  &__title{
    margin-top: $value;
    color: $bg__dark;
  }


  &--post {
    margin-bottom: $value * 1.5;
  }
  &--home {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    @include breakpoint(mobileonly){
      box-shadow: 0 $value $value * 2 rgba(black,.1);
      border-radius: $value * .5;
    }
    &.c-card--home--horizontal {
      & .c-card__image {
        height: 250px;
        width: 250px;
      }
    }
    & .c-card__actions {
      margin-top: auto;
    }
  }
  &--link{
    position: relative;
    color: white;
    text-decoration: none;
    & .c-card__link{
      position: absolute;
      right: $value;
      top: $value;
      color: $color-secondary;
      & span{
        font-size: $value * 2;
      }
    }
    & .c-card__image{
      width: 100%;
      margin-bottom: 0;
      background-color: white;
      border-radius: $value $value 0 0;
      & .image{
        background-color: white;
        border-radius: 0;
        overflow: none;
        position: relative;
        &--logo{
          width: 50%;
          height: auto;
          object-fit: contain;
        }
      } 
      @include breakpoint(desktop){
        width: 40%;
        border-radius: $value 0 0 $value;
      }
    }
    & .c-card__info{
      position: relative;
      width: 100%;
      background-color: $bg__dark;
      border-radius: 0 0 $value $value;
      @include breakpoint(desktop){
        border-radius: 0 $value $value 0;
        width: 60%;
      }
    }
  }
  &--projects{
    background-color: $bg__dark;
    border-radius: $value;
    color: white;
    overflow: hidden;
    border: 1px solid darken($bg__light,5);
    text-decoration: none;
   
    & .c-card__title {
      & h3 {
        color: $bg__white;
      }
    }
    & .c-card__image{
      background-color: $bg__white;
      height: 100%;
      margin-bottom: 0;
      width: 40%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      @include breakpoint(mobileonly) {
        height: 50%;
        width: 100%;
      }
    }
    & .c-card__info{
      width: 60%;
      position: relative;
    }
    & .c-card__description{
      & p{
        color: $bg__white;
      }
    }
  }

  &--proj-and-init {
    background-color: $bg__light;
    border-radius: $value;
    padding: $value * 1.5;
    & .c-card__image {
      &:after {
        display: none;
      }
      margin: 0;
      & img{
        width: 60%;
        height: auto;
      }
    }
    & .c-card__info {
      padding: $value;
      padding-bottom: 0;
      & .p-small {
        color: rgba($bg__dark, .5);
        opacity: .5;
        margin-bottom: 0;
      }
    }
  }

  &--reorgnize {
    background-color: darken($bg__dark, 9);
    border-radius: $value;
    color: $bg__white;
    & .c-card__image {
      height: 200px;
      margin-bottom: 0;
      position: relative;
      &-separator{
        position: absolute;
        left: 0;
        bottom: -10%;
        & img{
          width: 100%;
          height: auto;
        }

      }
    }
    & .c-card__info {
      padding: $value * 2;
      & .c-card__title {
        color: $qe__red;
        margin-bottom: $value;
      }
      & .c-card__description {
        & .p-normal, & p, & a {
          color: $bg__white;
        }
        & .p-normal {
          margin-bottom: $value * 5;
        }
        & a {
          font-weight: 700;
        }
      }
      & .c-card__actions {
        margin-top: $value * 2.5;
      }
    }
  }

  &--featured-post {
    display: flex;
    background-color: $bg__dark;
    border-radius: $value;
    overflow: hidden;
    @include breakpoint(mobileonly) {
      flex-direction: column;
    }
    & .c-card__image, & .c-card__info {
      flex: 1;
    }
    & .c-card__image {
      margin-bottom: 0;
      & .image {
        border-radius: 0;
      }
    }
    & .c-card__info {
      padding: $value * 3;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @include breakpoint(mobileonly) {
        order: 2
      }
      & .c-card__title {
        color: $bg__white;
        font-size: $value * 1.8;
        font-weight: 600;
      }
    }
  }
}