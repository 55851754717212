$value:1rem;

@mixin keyframes-animation($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}
@mixin prefix-property($name, $value) {
  -webkit-#{$name}: $value;
          #{$name}: $value;
}

@include keyframes-animation(navlink-animation) {
  0% {
    right: 0;
    left: 0;
  }
  100% {
    right: 50%;
    left: 50%;
  }
}

@include keyframes-animation(navlinkanimation) {
  0% {
    margin-top: 30px;
  }
  100% {
    margin-top: 0;
  }
}

@mixin mx-text($size:'', $weight:'normal', $line-height:normal) {
  $percent:100;
  @include mx-responde-bajo(hd){
    $value : $value+1;
  }

  @if $weight=='bold' {
    font-weight: bold;
  }
    @if $line-height==normal {
      line-height: normal;
    } @else {
      line-height: #{$line-height * $percent + '%'};
    }
  @if $size == 'h1'{
    font-size: $value * 5;
  }
  @if $size == 'h2'{
    font-size: $value * 4;
  }
  @if $size == 'h3'{
    font-size: $value * 3;
  }
  @if $size == 'h4'{
    font-size: $value * 2;
  }
  @if $size == 'large'{
    font-size: $value * 2;
  }
  @if $size == 'medium'{
    font-size: $value * 1.5;
  }
  @if $size == 'normal'{
    font-size: $value ;
  }
  @if $size == 'small'{
    font-size: $value * .75;
  }
  @if $size == 'caption'{
    font-size: $value ;
    text-transform: uppercase;
  }
}



@mixin breakpoint($point) {
  @if $point == monitor {
    @media (min-width: 1400px) { @content ; }
  }
  @if $point == desktop {
    @media (min-width: 1200px) { @content ; }
  }
  @else if $point == laptop {
    @media (min-width: 992px) { @content ; }
  }
  @else if $point == tablet {
    @media (min-width: 768px) { @content ; }
  }
  @else if $point == phablet {
    @media (min-width: 576px)  { @content ; }
  }
  @else if $point == navonly {
    @media (max-width: 991px)  { @content ; }
  }
  @else if $point == mobileonly {
    @media (max-width: 576px)  { @content ; }
  }
}

@mixin all-transitions($time) {
  -webkit-transition: all #{$time}s ease-in-out;
  -moz-transition: all #{$time}s ease-in-out;
  -o-transition: all #{$time}s ease-in-out;
  transition: all #{$time}s ease-in-out;
}
