
.si-content {
    margin: 50px auto;
    width: 80%; 
    max-width: 480px;
}

.si-content-r {
    margin: 50px auto;
    width: 80%; 
    max-width: 600px;
}


.si-marco {
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 16px;
    padding: 20px;
    background: white;
}

.si-separador {
    margin-top: 25px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.c-fullw {
    width: 100%;
    text-align: center;
    align-items: center;
}

 