a:link {
  color: rgba(43, 68, 255, 1);
  text-decoration: none;
}

a:hover {
  color: rgba(37, 58, 216, 0.8);
}

a:visited {
  color: rgba(43, 68, 255, 1);;
}

.link:link {
  color: rgba(43, 68, 255, 1);
  text-decoration: none;
}

.link:hover {
  color: rgba(37, 58, 216, 0.8);
}

.link:visited {
  color: rgba(43, 68, 255, 1);;
}

