.c-brand{
  width: $value * 16;
  & img{
    width: 100%;
    height: auto;
  }
  &--sm{
    width: $value * 12;
  }
  &--xs{
    width: $value * 8;
  }
}