.App-logo {
  width: 135px;
  margin: 10px;
}


.h-row {
  display: table;
  width: 100%; /*Optional*/
  table-layout: fixed; /*Optional*/
}
.h-col {
  display: table-cell;
}

.h-log {
  text-align: right;
  padding-right: 40px;
  vertical-align: top;
}


.l-header {
  height: 97px;

  & .navbar-brand {
    padding: 0;
  }
  & .navbar-toggler-icon{
    
  }

  & .navbar-nav {
    & .nav-item {
      height: 95px;
      display: flex;
      align-items: center;
      margin: 0 12px;
      &.active {
        border-bottom: 2px solid $qe__red;
        & .nav-link {
          color: $qe__red;
        }
      }
      &.dropdown {
        & .nav-link {
          display: flex;
          align-items: center;
          &:after {
            display: none;
          }
          background-color: rgba(46,24,106,0.05);
          border-radius: 8px;

          & span {
            margin-left: 28px;
          }
        }
        @include breakpoint(mobileonly) {
          flex-direction: column;
        }
      }
      & .nav-link {
        color: black;
        font-size: 18px;
        padding: 10px 18px;
      }

      & .l-header__dropdown {
        @include breakpoint(mobileonly) {
          display: block;
          width: 100%;
          left: 0;
          position: relative;
          top: -60px;
          padding: 30px;
          border-top-right-radius: 0;
          border-top-left-radius: 0;
          &:before {
            display: none;
          }
        }
        &.show {
          display: grid;
        }
        padding: 44px 60px 32px;
        border-radius: 16px;
        background-color: $bg__dark;
        color: $bg__white;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 40px;
        grid-row-gap: 40px;
        width: 800px;
        left: inherit;
        right: 0;
        &:before {
          content: "";
          position: absolute;
          top: -16px;
          right: 60px;
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 16px solid $bg__dark;
        }
        & .dropdown__item {
          max-width: 200px;
          position: relative;
          @include breakpoint(mobileonly) {
            max-width: 100%;
          }
          & .item__header {
            & .p-small {
              color: $qe__red;
              margin-bottom: 0;
            }
            @include breakpoint(mobileonly) {
              display: none;
            }
          }
          & .item__title {
            & h3 {
              color: #fff;
              @include breakpoint(mobileonly) {
                font-size: 18px;
                font-weight: normal;
              }
            }
          }
          & .item__description {
            & .p-small {
              color: $bg__white;
              @include breakpoint(mobileonly) {
                opacity: .5;
              }
            }
          }
          & .item__link {
            color: $bg__white;
            @include breakpoint(mobileonly) {
              position: absolute;
              top: 12px;
              right: 10px;
            }
          }
        }
      }
    }
  }
}