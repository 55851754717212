body {
  font-family: 'Poppins', sans-serif;
  height: 100vh;
  background: #E5E5E5;
}

main {
  height: calc(100% - 140px);
}

#root, #root > div {
  height: 100%;
}

.App {
  width: 100%;
  height: 100%;
  
}

.hide {
  display: none;
}