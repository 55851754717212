h1 {
  font-size: $value * 4.5;
  line-height: $value * 5.25;
  font-weight: 600;
  color: $tx__active;
  @include breakpoint(mobileonly){
    font-size: $value * 2.25;
    line-height: $value * 2.5;
  }
}
h2 {
  font-size: $value * 2.25;
  line-height: $value * 3;
  font-weight: 600;
  color: $tx__active;
  @include breakpoint(mobileonly){
    font-size: $value * 2;
    line-height: $value * 2.5;
  }
}
h3 {
  color: $tx__active;
  font-size: $value * 2;
  line-height: $value * 3;
  font-weight: 600;
}
h4 {
  color: $tx__active;
  font-size: $value * 1.6;
  line-height: $value * 1.5;
  font-weight: 500;
}
h5 {
  color: $tx__active;
  font-size: 15px;
  line-height: 22.5px;
  font-weight: 600;
}
p{
  color: $tx__type;
}

.p-normal {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: $tx__type;
}

.p-highlight {
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;

}

.p-small {
  font-size: 12px;
  line-height: 18.88px;

}
 .caption{
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.05em;

 }
 .text-underline{
   text-decoration: underline;
 }

 .text-secondary{
   color: $color-secondary;
 }
 .text-primary{
  color: $color-primary;
 }

 .side-note {
   font-size: 10px;
   color: #aaa;
   margin-top: 30px;
 }

 .f-error {
  font-size: 12px;
  color: red;
}

.nor {
  font-weight: normal;
}

.r-bold {
  color: black;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18.88px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.m-title {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
  width: 100%;
}

.m-bajada {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  width: 100%;
}