
.rg-content {
    width: 90%; 
    max-width: 1100px;
}

.rg-marco {
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 16px;
    padding: 20px;
}

.rg-separador {
    margin-top: 25px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.rg-row {
    display: table;
    width: 100%; /*Optional*/
    table-layout: fixed; /*Optional*/
}
.rg-col {
    display: table-cell;
}