
.up-content {
    margin: 50px auto;
    width: 80%; 
    max-width: 500px;
}

.up-marco {
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 16px;
    padding: 20px;
}

.up-separador {
    margin-top: 10px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

/* width */
.tc::-webkit-scrollbar {
    width: 7px;
  }
  
  /* Track */
.tc::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
.tc::-webkit-scrollbar-thumb {
    background: #2B44FF;
  }
  
  /* Handle on hover */
.tc::-webkit-scrollbar-thumb:hover {
    background: #999;
  } 

.tc p {
    color: #666;
    font-size: 12px;
    padding-right: 20px;
    text-align: justify;
} 

.tc h5 {
    margin-block-end: 8px;
    margin-block-start: 5px;
}

.tct {
    color: #333;
    font-size: 24px;
    font-weight: 500;
} 
