.l-footer {
  height: 425px;
  background-color: $bg__light;
  position: relative;
  padding-top: $value  * 5;
  & .footer__title {
    @include breakpoint(mobileonly){
      width: 100%;
      display: block;
    }
    & .p-small {
      margin-bottom: 0;
      color: black;
      font-weight: 700;
      margin-right: 38px;
    }
  }
  & .foot-label{
    white-space: nowrap;
  }
  & .footer__logos {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    & .logo__image {
      width: $value * 8;
      padding: $value $value *  .5;
     
      & img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    @include breakpoint(mobileonly){
      width: 100%;
      justify-content: center;
      display: flex;
      flex-wrap: wrap;
      & .logo__image{
        width: 50%;

        text-align: center;
      }
    }
  }

  & .footer_rights {
    position: absolute;
    bottom: 32px;
    width: 100%;
    text-align: center;
    left: 0;
    & .p-small {
      color: rgba(black, .5);
    }
  }
}