//MAIN
$qe__blue: #2B44FF;
$qe__red: #EB3C46;

//DTP
$dtp__primary:#57D5DB;
//EDE
$ede__primary:#04B98C;

//BACKGROUNDS
$bg__dark:#191847;
$bg__light:#F9FAFC;
$bg__white:#ffffff;

//TEXT
$tx__active:#000000;
$tx__type:#425466;




$color-primary: $qe__blue;
$color-primary--light: lighten($qe__blue,98);

$color-secondary:$qe__red;
$color-secondary--light: lighten($qe__red,98);


.bg-color-primary {
  background-color: $color-primary;
}
.bg-color-secondary {
  background-color: $color-secondary;
}
.bg-color-dark {
  background-color: $bg__dark;
}
.bg-color-light {
  background-color: $bg__light;
}
.bg-color-white {
  background-color: $bg__white !important;
}
.bg-color-type{
  background-color: $tx__type;
}
.bg-color-type-active{
  background-color: $tx__active;
}

.text-secondary {
  color: $color-secondary!important;
}
.text-darken--light {
  color: $bg__dark;
  opacity: .5;
}